/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",    
    "aws_cognito_region": "eu-central",
    "aws_user_pools_id": "eu-central-1_FaCZR3T20",
    "aws_user_pools_web_client_id": "7l4l5thincokqgu4stb8adp4a1",
    "oauth": {},    
    "aws_user_files_s3_bucket": "wondersouq-k8s-production",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;

